import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MyService } from '../../../shared/api/my.service';
import {
  FormErrors,
  KaisatsuResponseError,
} from '../../../shared/api/response.error';
import { GlobalMessageService } from '../../../shared/global-message.service';
import { confirmationMatchValidator } from '../../../shared/validators/confirmation-match';
import { ConfirmPasswordDialogComponent } from '../../../shared/confirm-password-dialog/confirm-password-dialog.component';
import { kaisatsuHandleError } from '../../../shared/kaisatsu-error-handler';

@Component({
  selector: 'app-password-setting-form',
  templateUrl: './password-setting-form.component.html',
  styleUrls: ['./password-setting-form.component.scss'],
})
export class PasswordSettingFormComponent {
  @ViewChild(ConfirmPasswordDialogComponent, { static: true })
  confirmPasswordDialog!: ConfirmPasswordDialogComponent;

  readonly form = this.fb.group(
    {
      new_password: this.fb.control('', { validators: [Validators.required] }),
      new_password_confirmation: this.fb.control('', {
        validators: [Validators.required],
      }),
    },
    {
      validators: [
        confirmationMatchValidator('new_password', 'new_password_confirmation'),
      ],
    }
  );
  isPasswordVisible = false;
  submitInProgress = false;
  formErrors: FormErrors | null = null;
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly myService: MyService,
    private readonly globalMessageService: GlobalMessageService
  ) {}

  get newPasswordConfirmation() {
    return this.form.get('new_password_confirmation');
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  public async onSubmit(): Promise<void> {
    this.submitInProgress = true;
    this.globalMessageService.delete();
    try {
      this.formErrors = null;
      await this.myService.putPassword(this.form.value);
      this.globalMessageService.setSuccessMessage('パスワードを変更しました。');

      // formは初期化する。
      this.form.reset();
      // 上部に成功メッセージが表示されるので一番上にスクロール
      window.scrollTo(0, 0);
    } catch (e: KaisatsuResponseError | unknown) {
      if (e instanceof KaisatsuResponseError) {
        if (e.messages.sudo_required) {
          // 現在のパスワード入力を要求する
          this.confirmPasswordDialog.open();
        } else {
          // グローバルに表示するエラーメッセージをここで保存
          this.globalMessageService.setErrorMessage(e.messages);
        }
        // フォームに関するエラーがある場合はフォームに渡す
        if (e.messages?.form) {
          this.formErrors = {
            ...e.messages.form,
            new_password: e.messages.form.new_password?.replace('\\n', ' '), // 複数バリデーションエラーがある場合のケア
          };
        }
      } else {
        kaisatsuHandleError(e, this.globalMessageService);
      }
    }
    this.submitInProgress = false;
  }

  retryOnSubmit(): void {
    this.onSubmit();
  }
}
