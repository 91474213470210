<app-header
  *ngIf="user"
  [user]="user"
  serviceName="マナビジョン連携"
></app-header>

<main class="{{ iframe ? 'iframe' : '' }}">
  <spen-heading [level]="2" [headingStyle]="'size-l'" [hasMarginBottom]="true"
    >マナビジョン連携</spen-heading
  >

  <app-global-message [isMarginBottom]="true"></app-global-message>

  <div class="message">
    生徒がClassiのIDで、マナビジョンへログインできるようにします。<br />ご利用いただくには、事前に「ベネッセの模試データ連携」を行う必要があります。
  </div>

  <section class="csv download">
    <spen-heading [level]="2" headingStyle="size-s" [hasMarginBottom]="true"
      ><p class="title">連携対象を選択する</p></spen-heading
    >
    <app-download-form-manabi
      *ngIf="searchOptions"
      [schoolYears]="searchOptions.school_years"
      [schoolStages]="searchOptions.stages"
      [grades]="searchOptions.grades"
      [classrooms]="searchOptions.classrooms"
      (changeSchoolYear)="updateSearchOptions($event)"
    ></app-download-form-manabi>
  </section>

  <section class="csv upload">
    <spen-heading [level]="2" headingStyle="size-s" [hasMarginBottom]="true"
      ><p class="title">データを登録する</p></spen-heading
    >
    <div>
      <a href="{{ bhsoUrl }}" target="_blank">Benesse High School Online</a> に
      ダウンロードしたファイルをアップロードしてください。
    </div>
  </section>
</main>

<app-footer [user]="user"></app-footer>
